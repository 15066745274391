import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
} from "@mui/material";
import { headerLogo, closeSidebarArrow, allUsersImg, activeAllUsersImg, assessmentImg, activeAssessmentImg, companyImg, activeCompanyImg, menuBgImg, competenciesImg, activeCompetenciesImg, criticalImg, activeCriticalImg, dashboardImg, activeDashboardImg, jobImg, activeJobImg, logoutImg, questionsImg, activeQuestionsImg } from "./assets";
// Customizable Area End

import DashboardSidebarController, {
    Props,
} from "./DashboardSidebarController";

export default class DashboardSidebar extends DashboardSidebarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    dashboardTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Dashboard' ? activeDashboardImg.default : dashboardImg.default}
                    alt="Dashboard"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Dashboard' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Dashboard
                </Typography>
            </>
        )
    }

    companyDetailsTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Company details' ? activeCompanyImg.default : companyImg.default}
                    alt="Company details"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Company details' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Company details
                </Typography>
            </>
        )
    }

    jobDescriptionsTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Job descriptions' ? activeJobImg.default : jobImg.default}
                    alt="Job description"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Job descriptions' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Job descriptions
                </Typography>
            </>
        )
    }

    compitenciesTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Compitencies' ? activeCompetenciesImg.default : competenciesImg.default}
                    alt="Compitencies"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Compitencies' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Compitencies
                </Typography>
            </>
        )
    }

    criticalPositionTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Critical position' ? activeCriticalImg.default : criticalImg.default}
                    alt="Critical position"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Critical position' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Critical position
                </Typography>
            </>
        )
    }

    allUsersTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'All users' ? activeAllUsersImg.default : allUsersImg.default}
                    alt="All users"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'All users' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    All users
                </Typography>
            </>
        )
    }

    questionBanksTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Question banks' ? activeQuestionsImg.default : questionsImg.default}
                    alt="Question banks"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Question banks' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Question banks
                </Typography>
            </>
        )
    }

    assessmentsTab = () => {
        return (
            <>
                <img
                    src={this.props.selectedMenu === 'Assessments' ? activeAssessmentImg.default : assessmentImg.default}
                    alt="Assessments"
                    className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Assessments' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Assessments
                </Typography>
            </>
        )
    }

    logoutTab = () => {
        return (
            <>
                <img src={logoutImg.default} alt="Logout" className="menuImg" />
                <Typography className={`${this.props.selectedMenu === 'Logout' ? 'activeMenuTitle' : 'menuTitle'}`}>
                    Logout
                </Typography>
            </>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Wrapper>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={11} className="logoWrapper">
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <img src={headerLogo.default} alt="Logo" data-test-id="navigate_to_home" onClick={this.navigateToHome} />
                            </Grid>
                            <Grid item>
                                <img src={closeSidebarArrow.default} alt="Close Sidebar" data-test-id="close_sidebar" onClick={this.props.toggleSideMenu} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11}
                        onClick={() => this.navigateToDashboard()}
                        data-test-id="navigate_to_dashboard"
                        className={`menu ${this.props.selectedMenu === 'Dashboard' ? 'menuSelected' : ''}`}>
                        {this.dashboardTab()}
                    </Grid>

                    <Grid item xs={11}
                        onClick={() => this.navigateToCompanyDetails()}
                        data-test-id="navigate_to_company_details"
                        className={`menu ${this.props.selectedMenu === 'Company details' ? 'menuSelected' : ''}`}>
                        {this.companyDetailsTab()}
                    </Grid>
                    <Grid item xs={11} data-test-id="navigate_to_jobDescription" onClick={this.navigateToJobDescription}
                        className={`menu ${this.props.selectedMenu === 'Job descriptions' ? 'menuSelected' : ''}`}>
                        {this.jobDescriptionsTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'Compitencies' ? 'menuSelected' : ''}`}>
                        {this.compitenciesTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'Critical position' ? 'menuSelected' : ''}`}>
                        {this.criticalPositionTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'All users' ? 'menuSelected' : ''}`}>
                        {this.allUsersTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'Question banks' ? 'menuSelected' : ''}`}>
                        {this.questionBanksTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'Assessments' ? 'menuSelected' : ''}`}>
                        {this.assessmentsTab()}
                    </Grid>
                    <Grid item xs={11}
                        className={`menu ${this.props.selectedMenu === 'Logout' ? 'menuSelected' : ''}`}>
                        {this.logoutTab()}
                    </Grid>
                </Grid>
            </Wrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    borderRight: "1px solid rgba(236, 236, 236, 1)",
    height: "100%",
    minHeight: "100vh",
    "& .logoWrapper": {
        height: "150px"
    },
    "& .menu": {
        padding: "18px",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer"
    },
    "& .menuSelected": {
        backgroundImage: `url(${menuBgImg.default})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: "260px",
        height: "50px",
        borderRadius: "5px 0px 0px 0px",
        opacity: "0.1px"
    },
    "& .menuImg": {
        width: "22px",
        height: "22px"
    },
    "& .menuTitle": {
        paddingLeft: "10px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        color: "#8A96A8",
        "@media(max-width:960px)": {
            fontSize: "14px",
        }
    },
    "& .activeMenuTitle": {
        paddingLeft: "10px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        color: "#044352",
        "@media(max-width:960px)": {
            fontSize: "14px",
        }
    }
})
// Customizable Area End