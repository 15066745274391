import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedIndustryType: string;
  industryType: string[];
  selectedCompanySize: string;
  companySize: string[];
  selectedCompanyStage: string;
  companyStage: string[];
  selectedJdStyle: string;
  jdStyle: string[];
  companyCulture: string;
  organizationKeywords: string;
  chipData: string[];
  selectedFile: File | null;
  fileError: string;
  submitClicked: boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      selectedIndustryType: "",
      industryType: ["Industry1", "industry2", "industry3"],
      selectedCompanySize: "",
      companySize: ["companySize1", "companySize2", "companySize3"],
      selectedCompanyStage: "",
      companyStage: ["companyStage1", "companyStage2", "companyStage3"],
      selectedJdStyle: "",
      jdStyle: ["jdStyle1", "jdStyle2", "jdStyle3"],
      companyCulture: "",
      organizationKeywords: "",
      chipData: [],
      selectedFile: null,
      fileError: "",
      submitClicked: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

  }

  handleCopanyCulture = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ companyCulture: event.target.value.length > 1000 ? this.state.companyCulture : event.target.value })
  }

  handleIndustryType = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedIndustryType: event.target.value });
  }

  handleCompanySize = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCompanySize: event.target.value })
  }

  handleCompanyStage = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCompanyStage: event.target.value })
  }

  handleJdStyle = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedJdStyle: event.target.value })
  }

  handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ organizationKeywords: event.target.value });
  };

  handleAddChip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.state.organizationKeywords.trim()) {
      this.setState((prevState) => ({
        chipData: [...prevState.chipData, prevState.organizationKeywords],
        organizationKeywords: ""
      }))
    }
  };

  handleDeleteChip = (chipToDelete: string) => () => {
    this.setState((prevState) => ({
      chipData: prevState.chipData.filter((chip) => chip !== chipToDelete),
    }));
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      const maxSizeInMB = 5;
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(selectedFile.type) || (selectedFile.size > maxSizeInMB * 1024 * 1024)) {
        this.setState({fileError: configJSON.fileErrorText})
        return;
      } else {
      this.setState({ selectedFile, fileError: "" });
      }
    }
  };

  handleImageClick = () => {
    const fileInput = document.getElementById('upload-jd-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  handleFileDelete = () => {
    this.setState({ selectedFile: null, fileError: "" })
  }

  handleCompanyDetailSubmit = () => {
    this.setState({ submitClicked: true })
    const errorInCompanyCulture = !this.state.companyCulture;
    const errorInIndustryType = !this.state.selectedIndustryType;
    const errorInCompanySize = !this.state.selectedCompanySize;
    const errorInCompanyStage = !this.state.selectedCompanyStage;
    const errorInJdstyle = !this.state.selectedJdStyle;
    const errorInKeywords = this.state.chipData.length < 1;
    const errorInFile = !this.state.selectedFile

    if (errorInCompanyCulture || errorInIndustryType || errorInCompanySize || errorInCompanyStage || errorInJdstyle || errorInKeywords || errorInFile) {
      return
    }
  }
  // Customizable Area End

}
