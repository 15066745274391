import React from "react";

// Customizable Area Start
import { Box, Typography, TextField, Grid,InputAdornment,TextareaAutosize, OutlinedInput, FormControl, Button, styled, Modal, LinearProgress } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import * as Yup from "yup";
import  DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import {arrow_Left, drop_Down, progress } from "./assets";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
// Customizable Area End

import Cfchatgptintegration9Controller, {
  Props,
  configJSON,
} from "./Cfchatgptintegration9Controller";

export default class Cfchatgptintegration9 extends Cfchatgptintegration9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  jobDescValidationSchema = Yup.object({
    jobTitle: Yup.string().required("Required field"),
    DepartmentTeam: Yup.string().required("Required field"),
    jobLevel: Yup.string().required("Required field"),
    jdStyle: Yup.string().required("Required field"),
    jobSummary: Yup.string().required("Required field"),  
    keySkills: Yup.string().required("Required field"),
    keyWords: Yup.string().required("Required field")
  });
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}>
        <MainWrapper>
        <Formik
            data-test-id="formik"
            initialValues={{
              jobTitle: '',
              DepartmentTeam: '',
              jobLevel: '',
              jdStyle: '',
              jobSummary: '',
              keySkills: '',
              keyWords: ''
            }}
            validationSchema={this.jobDescValidationSchema}
            onSubmit={(values: any) => {
              this.jobDescriptionCall(values)
            }}
          >
            {({
              handleSubmit,
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
          <Grid container className="gridContainer">
            <Grid item xs={12}>
              <Box className="container">
                <Box className="heading">
                <Box data-test-id="Dashboard" onClick={!this.state.progressScreen ? this.navigateToDashboard : this.progressScreenOff} style={{ width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={arrow_Left.default} />
                  </Box>
                  <Typography className="head">{!this.state.progressScreen ? configJSON.jobdesGen : configJSON.genJobDesProgress}</Typography>
                </Box>
                {!this.state.progressScreen 
                ? 
                <Box className="formMainContainer">
                <Box className="formcontainer">
                  <Typography className="titleHeading">Job Context</Typography>
                  <Box style={{ border: "1px solid #E2E8F0" }}></Box>
                  <Box className="nameContainer">
                    <Box className="nameInput">
                      <Box className="labeltexted">
                        <Typography className="labeltext">
                          Job Title *
                        </Typography>
                        <ErrorMessage name="jobTitle" component="div" className="error" />
                      </Box>
                      <TextField
                        className="nameInputText"
                        placeholder="Specify your title"
                        fullWidth
                        name="jobTitle"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobTitle}
                      />
                    </Box>
                    <Box className="nameInput">
                      <Box className="labeltexted">
                        <Typography className="labeltext">
                          Department/Team *
                        </Typography>
                        <ErrorMessage name="DepartmentTeam" component="div" className="error" />
                      </Box>
                      <TextField
                        className="nameInputText"
                        placeholder="Specify your department/team"
                        fullWidth
                        name="DepartmentTeam"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DepartmentTeam}
                      />
                    </Box>
                  </Box>
                          <Box className="nameContainer">
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Job Level *
                                </Typography>
                                <ErrorMessage name="jobLevel" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown" data-test-id="setJobLevel" onClick={this.setJobLevel}>
                                <ClickAwayListener
                                data-test-id="setClickJobLevel"
                                  mouseEvent="onMouseDown"
                                  touchEvent="onTouchStart"
                                  onClickAway={this.setClickJobLevel}>
                                  <>
                                    <FormControl variant="outlined" className="nameInputText">
                                      <OutlinedInput
                                        placeholder="Specify your title"
                                        name="jobLevel"
                                        value={values.jobLevel}
                                        endAdornment={<InputAdornment position="end">
                                          <img src={drop_Down.default} />
                                        </InputAdornment>}
                                      />
                                    </FormControl>
                                    {this.state.jobLevelDropDown && (
                                      <Box className="menu">
                                        {this.state.jobLevel?.length >0 && this.state.jobLevel?.map((data: any, index: number) => 
                                        <Typography className="menuItem" data-test-id={`menuItem1${index}`}
                                          onClick={() => {
                                            setFieldValue("jobLevel", data.name)
                                          }}>{data.name}</Typography>)}
                                      </Box>
                                    )}</>
                                </ClickAwayListener>
                              </Box>
                            </Box>
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  JD style *
                                </Typography>
                                <ErrorMessage name="jdStyle" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown" data-test-id="setJdStyle" onClick={this.setJdStyle}>
                                <ClickAwayListener
                                  data-test-id="setClickJdStyle"
                                  mouseEvent="onMouseDown"
                                  touchEvent="onTouchStart"
                                  onClickAway={this.setClickJdStyle}>
                                  <>
                                    <FormControl variant="outlined" className="nameInputText">
                                      <OutlinedInput
                                        placeholder="Select your JD className"
                                        name="jdStyle"
                                        value={values.jdStyle}
                                        endAdornment={<InputAdornment position="end">
                                          <img src={drop_Down.default} />
                                        </InputAdornment>}
                                      />
                                    </FormControl>
                                    {this.state.jdStyleDropDown && (
                                      <Box className="menu">
                                        {this.state.jdstyle?.length > 0 && this.state.jdstyle?.map((data: any, index: number) => <Typography className="menuItem" 
                                        data-test-id={`menuItem2${index}`}
                                          onClick={() => setFieldValue("jdStyle", data.name)}>{data.name}</Typography>)}
                                      </Box>
                                    )}
                                  </>
                                </ClickAwayListener>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Box className="desInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Job Summary *
                                </Typography>
                                <ErrorMessage name="jobSummary" component="div" className="error" />
                              </Box>
                              <TextareaAutosize className="descInputText" minRows={5} placeholder="Write about your job..."
                                onChange={handleChange}
                                name="jobSummary"
                                onBlur={handleBlur}
                                value={values.jobSummary} />
                              <Typography className="limit">0/1000</Typography>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Key Skills *
                                </Typography>
                                <ErrorMessage name="keySkills" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown">
                                <TextField
                                className="nameInputText"
                                placeholder="Select your key skills"
                                fullWidth
                                name="keySkills"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keySkills}
                              />
                              </Box>
                            </Box>
                            <Box className="nameInput">
                              <Box className="labeltexted">
                                <Typography className="labeltext">
                                  Key Words *
                                </Typography>
                                <ErrorMessage name="keyWords" component="div" className="error" />
                              </Box>
                              <Box className="mainDropDown">
                                <TextField
                                className="nameInputText"
                                placeholder="Select your key words"
                                fullWidth
                                name="keyWords"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keyWords}
                              />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="nameContainer">
                            <Button className="submit" type="submit">Submit</Button>
                          </Box>
                        </Box>
                      </Box>
                :
                <Box className="progressMainContainer">
                <Box className="progressContainer">
                <Box className="progressImage">
                  <img src={progress.default} />
                </Box>
                <Box className="dotsProgress">
                  {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box style={{width:"7.5px" , height:"7.5px" , 
                  borderRadius:"50%", backgroundColor:"#059669",
                  transform: `${index === this.state.active ? "scale(1.5)" : "scale(1)"}`
                  }}></Box>)}
                </Box>
                <Box className="linearProgressBar">
                    <LinearProgress
                      className="linearProgress"
                      variant="determinate"
                      value={35}
                    />
                  </Box>
                    <Box className="ProgressTextContain">
                      <Typography className="progressText">
                        35% Complete, Finalising Job Description..
                      </Typography>
                    </Box>
                    <Box className="ProgressCreationContent">
                      <Typography className="progressContent1">
                        Hang tight! Job Description Creation in Progress...
                      </Typography>
                      <Typography className="progressContent2">
                        Please wait while we generate the Job Description based on your inputs.
                      </Typography>
                    </Box>
                </Box>
              </Box>
                }
              </Box>
            </Grid>
          </Grid>             
      </Form>
            )}
          </Formik>
          <Modal      
          open={this.state.modalOpen}
          onClose={this.closeModal}
>
          <CustomPopup 
          data-test-id="custom-popup"
          headingPopup={configJSON.headingPopup} 
          titlePopup={configJSON.titlePopup} 
          subTitlePopup = {configJSON.subTitlePopup}
          buttonText={configJSON.buttonText}
          onOpen={this.onOpen}
          onClose={this.closeModal}/>
      </Modal>
        </MainWrapper>
      </DashboardHeader>
      // Customizable Area End
    );
  }
}

export const MainWrapper = styled(Box)({
  width: "100%",
  height: "calc(100vh - 180px)",
  paddingBottom: "30px",
  "& .arabicDirection": {
    direction: "rtl",
  },
  "& .container": {
    width: "100vw",
  },
  "& .heading": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginLeft: "64px",
    marginTop: "42px",
    marginBottom: "50px",
  },
  "& .head":{
    fontSize: "25px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },
  "& .formMainContainer":{
    paddingLeft:"8rem",
    paddingBottom:"50px",
    "@media(max-width:1000px)": {
      paddingLeft:"4rem" ,
     },
     "@media(max-width:500px)": {
      paddingLeft:"2rem" ,
     },
  },
  "& .formcontainer": {
    width: "830px",
    paddingBottom:"50px",
    boxShadow: "0px 14px 144px 0px rgba(0, 0, 0, 0.1)",
    "@media(max-width:1000px)": {
     width: "fit-content",
    },
    "@media(min-width:1000px)": {
      width: "750px",
     },
     "@media(min-width:1200px)": {
      width: "830px",
     },
  },
  "& .titleHeading": {
    padding: "1.5rem",
    paddingLeft: "40px",
    color: "#044352",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },
  
  "& .nameInput": {
    width: "350px",
  },
  "& .labeltexted": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .labeltext": {
    display: "flex",
    color: "#344054",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Urbanist",
  },
  "& .error": {
      color: "#F87171",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Urbanist",
    },
  "& .mainDropDown":{
    position:"relative" as "relative",
  },
  "& .menu":{
    width:"350px",
    backgroundColor:"#FFFFFF",
    boxShadow: "0px 2px 4px 0px #00000026",
    position:"absolute" as "absolute",
    borderRadius:"8px",
    zIndex:10
  },
  "& .menuItem":{
    padding:"12px 16px 12px 16px",
    color:"#475569",
    "&::placeholder": {
    color: "#94A3B8",
}
  },
  "& .nameInputText": { "&::placeholder": {
    color: "#94A3B8",
    },
    width: "100%",
    borderColor:"#CBD5E1",
    borderRadius:"8px",color:"#475569", 
    fontWeight: 400, 
    fontSize: "16px" },
  "& .descInputText": {  
    "&::placeholder": {
    color: "rgba(0, 0, 0, 0.65)",
    }, 
    width: "100%",
    border:"1px solid #CBD5E1",
    borderRadius:"8px",
    color:"#475569", 
    fontWeight: 400, 
    fontSize: "16px", 
    padding:"8px" },
  "& .desInput": {
    width: "740px",
  },
  "& .limit":{
    display:"flex",
    justifyContent:"flex-end",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Urbanist",
  },
  "& .submit":{
    width: "740px",
    color:"#FFFFFF",
    backgroundColor:"#044352",
    fontWeight: 700, 
    fontSize: "16px",
    fontFamily: "Urbanist",
    zIndex:1
  },
  "& .nameContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "3rem",
    marginTop: "2rem",
    padding: "0rem !important",
    "@media(max-width:1000px)": {
      flexWrap: "wrap",
      width: "30rem",
      padding: "0 1rem",
    },
    "@media(max-width:500px)": {
      width: "23rem",
      padding: "1rem",
    },
    "@media(min-width:1000px)": {
      padding: "0 2rem"
    },
    "@media(min-width:1200px)": {
      width: "100%",
      flexWrap: "no-wrap",
     },
  },
  "& .progressMainContainer": {
    paddingLeft: "8rem",
    paddingBottom: "80px",
    "@media (max-width: 768px)": {
      paddingLeft: "6rem",
      paddingBottom: "40px",
    },
    "@media (max-width: 480px)": {
      paddingLeft: "5rem",
      paddingBottom: "20px",
    },
  },
  "& .progressContainer": {
    width: "1188px",
    height: "646px",
    boxShadow: "0px 14px 144px 0px #0000001A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width:1650px)": {
      width: "950px"
    },
    "@media(max-width:1350px)": {
      width: "650px"
    },
    "@media(max-width:970px)": {
      width: "550px"
    },
    "@media(max-width:720px)": {
      width: "450px"
    },
    "@media (max-width: 550px)": {
      width: "350px",
    },
    "@media (max-width: 450px)": {
      width: "250px",
    },
    "@media (max-width: 380px)": {
      width: "200px",
    },
  },
  "& .progressImage": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%", 
    height: "auto", 
    "@media (max-width: 768px)": {
      paddingBottom: "20px",
    },
  },
  "& .dotsProgress": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    gap: "7.5px",
    "@media (max-width: 768px)": {
      gap: "5px",
    },
    "@media (max-width: 480px)": {
      gap: "3px",
    },
  },
  "& .linearProgressBar": {
    width: "359px",
    paddingTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      width: "80%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  "& .linearProgress": {
    height: "10px",
    width: "100%",
    backgroundColor: "#E2E8F0",
    borderRadius: "5px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#059669",
    },
  },
  "& .progressText": {
    color: "#059669",
    fontFamily: "Urbanist",
    fontSize: "1.25rem",
    lineHeight: "1.75rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressCreationContent": {
    textAlign: "center",
    paddingTop: "62px",
    "@media (max-width: 768px)": {
      paddingTop: "40px",
    },
    "@media (max-width: 480px)": {
      paddingTop: "20px",
    },
  },
  "& .ProgressContent1": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressContent2": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .emptyMainText": {
    fontFamily: "Urbanist",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#000104"
  },
  "& .emptyNextText": {
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#8A96A8",
    width: "50%",
    textAlign: "center"
  },
  "& .imageBox": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .gridContainer": {
    height: "100%",
  }
});

// Customizable Area Start