import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Input,
  styled,
  Grid,
  Card,
  CardContent,
  Checkbox,
  InputAdornment,
  Select,
  IconButton,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
// Customizable Area End

import ContactusController, { Props, configJSON } from "./ContactusController";

// Customizable Area Start
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { successImg } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSuccessDialog = () => {
    return (
      <Dialog
        open={this.state.openSuccess}
        onClose={this.handleCloseSuccess}
        PaperProps={{ sx: { overflow: 'hidden', borderRadius: "8px 8px 32px 8px" } }}
      >
        <DialogTitle>
          <Wrapper>
            <Box className={`successTitleWrapper ${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}>

              <Typography className="successTitle">
                {this.state.selectedLanguage === "English" ? "Message Sent" : "تم إرسال الرسالة"}
              </Typography>
              <IconButton
                aria-label="close"
                data-test-id="close_success"
                onClick={this.handleCloseSuccess}
                className="successCloseIcon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Wrapper>
        </DialogTitle>
        <Divider sx={{ color: "#E2E8F0" }} />
        <DialogContent>
          <Wrapper>
            <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}>

              <Box className="successContentImageWrapper">
                <img src={successImg.default} height="144px" width="144px" />
              </Box>
              <Typography className="successContent1">
                {this.state.selectedLanguage === "English" ? "Thank you for reaching out!" : "شكرًا لك على تواصلك معنا."}
              </Typography>
              <Typography className="successContent2">
                {this.state.selectedLanguage === "English" ?
                  "In the meantime, feel free to explore more of our site or check your email for a confirmation." :
                  "في هذه الأثناء، لا تتردد في استكشاف المزيد من موقعنا أو التحقق من بريدك الإلكتروني للحصول على تأكيد."}
              </Typography>
            </Box>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Wrapper>
            <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}>
              <Box className="successContinueButtonWrapper">
                <Button
                  onClick={this.handleSuccessBrowsing}
                  variant="outlined"
                  data-test-id="continue_browsing"
                  className="successContinueButton"
                >
                  {this.state.selectedLanguage === "English" ? "Continue Browsing" : "متابعة التصفح"}
                </Button>
              </Box>
            </Box>
          </Wrapper>
        </DialogActions>
      </Dialog>
    )
  }

  firstNameTitle = () => {
    return this.state.selectedLanguage === "English" ? "First Name" : "الاسم الأول";
  }

  lastNameTitle = () => {
    return this.state.selectedLanguage === "English" ? "Last Name" : "اسم العائلة";
  }

  emailTitle = () => {
    return this.state.selectedLanguage === "English" ? "Email" : "بريد إلكتروني";
  }

  mobileNumberTitle = () => {
    return this.state.selectedLanguage === "English" ? "Mobile number" : "رقم الهاتف المحمول";
  }

  messageTitle = () => {
    return this.state.selectedLanguage === "English" ? "Message" : "رسالة";
  }

  privacyCheckBoxText = () => {
    return this.state.selectedLanguage === "English"
      ? <>You agree to our friendly <span className="privacySpan" onClick={this.props.handleClickOpenPrivacy}>privacy policy</span></>
      : <>أنت توافق على <span className="privacySpan" onClick={this.props.handleClickOpenPrivacy}>سياسة الخصوصية</span> الخاصة بنا</>;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper>
        <Grid container alignItems="center" justifyContent="center" className="contactUsContainer">
          <Grid item xs={12}>
            <Box className="contactUsDescMainBox">
              <Button className="contactUsButton">{this.state.getContactUs.data?.contact_us_title}</Button>
            </Box>
          </Grid>

          <Grid item className="contactUsTitleTextMainGrid">
            <Typography className="contactUsTitleText">{this.state.getContactUs.data?.contact_us_headline}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box className="contactUsItemMainBox">
              <Grid container className="cardContainer">
                <Grid item xs={12} className="cardItem">
                  <Card className="cardDescBox">
                    <CardContent>
                      <Grid container className="cardGridMargin2" spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography className="cardInputLabel">
                            {this.firstNameTitle()}
                          </Typography>
                          <Input
                            data-test-id="first_name"
                            className="enterInputField"
                            value={this.state.firstName}
                            disableUnderline
                            placeholder={this.firstNameTitle()}
                            onChange={(event) => this.firstNameChange(event)}
                            autoComplete="off"
                          />
                          <Typography className="errorStyle">
                            {this.state.sendMessageClicked && !this.state.firstName && this.requireMessage()}
                            {this.state.firstName && this.state.firstNameError}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography className="cardInputLabel">
                            {this.lastNameTitle()}
                          </Typography>
                          <Input
                            data-test-id="last_name"
                            className="enterInputField"
                            value={this.state.lastName}
                            disableUnderline
                            placeholder={this.lastNameTitle()}
                            onChange={(event) => this.lastNameChange(event)}
                            autoComplete="off"
                          />
                          <Typography className="errorStyle">
                            {this.state.sendMessageClicked && !this.state.lastName && this.requireMessage()}
                            {this.state.lastName && this.state.lastNameError}
                          </Typography>
                        </Grid>

                      </Grid>
                      <Grid container className="cardGridMargin1">
                        <Grid item xs={12} md={12}>
                          <Typography className="cardInputLabel">
                            {this.emailTitle()}
                          </Typography>
                          <Box className="directionArabic">
                            <Input
                              data-test-id="user_email"
                              className="enterInputField"
                              value={this.state.userEmail}
                              disableUnderline
                              placeholder="you@company.com"
                              autoComplete="off"
                              onChange={(event) => this.userEmailChange(event)}
                              onBlur={this.validationEmail}
                            />
                          </Box>
                          <Typography className="errorStyle">
                            {this.state.emailError.length > 0 && this.state.emailError}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="cardGridMargin1">
                        <Grid item xs={12} md={12}>
                          <Typography className="cardInputLabel">
                            {this.mobileNumberTitle()}
                          </Typography>
                          <Box className="directionArabic">
                            <TextField
                              fullWidth
                              data-test-id="mobile_number"
                              variant="outlined"
                              value={this.state.userPhoneNumber}
                              onChange={this.handlePhoneNumberChange}
                              placeholder="(555) 000-0000"
                              sx={{
                                '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                                  textAlign: "left"
                                },
                                '& .MuiOutlinedInput-root': {
                                  '&:focus-visible': {
                                    border: "none"
                                  },
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Select
                                      data-test-id="country_code"
                                      className="countryCodeText"
                                      value={this.state.countryCode}
                                      onChange={this.handleCountryCodeChange}
                                      variant="standard"
                                      IconComponent={(props) => <IconButton {...props} sx={{ padding: 0 }}><ExpandMoreIcon /></IconButton>}
                                      disableUnderline
                                      sx={{ minWidth: 70 }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 48 * 4,
                                            width: 'auto',
                                            overflowY: 'auto',
                                            marginLeft: "-10px"
                                          },
                                        },
                                      }}
                                    >
                                      {this.state.countryCodeData.country_codes?.map((code) => (
                                        <MenuItem key={code} value={`${code}`}>
                                          {`+${code}`}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Typography className="errorStyle">
                            {this.state.sendMessageClicked && !this.state.userPhoneNumber && this.requireMessage()}
                            {this.state.userPhoneNumber && this.state.phoneNumberError}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="cardGridMargin1">
                        <Grid item xs={12} md={12}>
                          <Typography className="cardInputLabel">
                            {this.messageTitle()}
                          </Typography>
                          <textarea
                            name="description"
                            data-test-id="message"
                            className="messageInputField"
                            value={this.state.message}
                            placeholder={this.messageTitle()}
                            onChange={(event) => this.messageChange(event)}
                          />
                          <Typography className="errorStyle">
                            {this.state.sendMessageClicked && !this.state.message && this.requireMessage()}
                            {this.state.sendMessageClicked && this.state.message && this.state.messageError}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="cardGridMargin1">
                        <Grid item xs={12} md={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', }}>
                            <Checkbox
                              data-test-id="privacy_check"
                              checked={this.state.privacyCheck}
                              name="privacyPolicy"
                              onChange={this.privacyCheckChange}
                              sx={{ mr: 1 }}
                              className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "privacyCheckbox" : "privacyCheckboxArabic"}`}
                            />
                            <Typography className="privacyTypo">
                              {this.privacyCheckBoxText()}
                            </Typography>
                          </Box>
                          <Typography className="errorStyle">
                            {this.state.sendMessageClicked && !this.state.privacyCheck &&
                              (this.state.selectedLanguage.toLowerCase() === "english" ?
                                configJSON.privacyErrorMsg :
                                configJSON.privacyErrorMsgArabic)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="cardGridMargin3">
                        <Grid item xs={12} md={12}>
                          <Button
                            className="sendMessageBtn"
                            onClick={this.handleSendMessage}
                            data-test-id="send_message"
                          >
                            {this.state.selectedLanguage === "English" ? "Send message" : "أرسل رسالة"}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {this.renderSuccessDialog()}
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

export const Wrapper = styled(Box)({
  width: "100%",
  "& .arabicDirection": {
    direction: "rtl",
  },
  "& .contactUsContainer": {
    marginTop: "94px",
    "@media(max-width:960px)": {
      marginTop: "60px",
    }
  },
  "& .contactUsDescMainBox": {
    display: "flex",
    justifyContent: "center"
  },
  "& .contactUsButton": {
    fontSize: "16px",
    background: "linear-gradient(97.86deg, #e4f2f7 -48.42%, #f0f7fa 120%), linear-gradient(97.86deg, rgba(4, 67, 82, 0.5) -48.42%, rgba(4, 67, 82, 0) 100%)",
    height: "48px",
    color: "#044352",
    padding: "0px 40px",
    backgroundOrigin: "border-box",
    position: 'relative',
    border: "2px solid transparent",
    borderRadius: "50px",
    backgroundClip: "padding-box, border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "700",
    fontFamily: "Urbanist",
    textTransform: "none",
    "&::before": {
      right: "-2px",
      content: '""',
      position: 'absolute',
      top: "-2px",
      bottom: "-2px",
      left: "-2px",
      borderRadius: "50px",
      zIndex: -1,
      background: "linear-gradient(97.86deg, rgba(4, 88, 82, 0.5) -100%, rgba(4, 67, 82, 0) 150%)",
    }
  },
  "& .contactUsTitleTextMainGrid": {
    margin: "16px",
    maxWidth: "45%",
    "@media(max-width:1390px)": {
      maxWidth: "73%"
    },
    "@media(max-width:1600px)": {
      maxWidth: "65%"
    },
    "@media(max-width:1700px)": {
      maxWidth: "75%"
    },
  },
  "& .contactUsTitleText": {
    fontFamily: "Urbanist",
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "44px",
    textAlign: "center",
    color: "#334155",
    "@media(max-width:1100px)": {
      fontSize: "32px",
    },
    "@media(max-width:960px)": {
      fontSize: "28px",
    },
    "@media(max-width:840px)": {
      fontSize: "24px",
    },
    "@media(max-width:720px)": {
      fontSize: "22px",
    },
    "@media(max-width:660px)": {
      fontSize: "20px",
      lineHeight: "36px"
    },
  },
  "& .contactUsItemMainBox": {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
  },
  "& .cardContainer": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    padding: " 0 8px",
    marginTop: "16px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .cardItem": {
    flex: "0 0 auto",
    scrollSnapAlign: "center",
    marginBottom: "100px",
    border: "1px solid rgba(0, 0, 0, 0.001)",
    boxShadow: "0px 13px 40px 0px rgba(75, 102, 135, 0.1)",
    "& .MuiPaper-root:hover": {
      border: "1px solid #4f96ff"
    },
  },
  "& .cardDescBox": {
    padding: "71px 200px",
    "@media(max-width:760px)": {
      padding: "24px 20px 28px 18px",
    },
  },
  "& .enterInputField": {
    fontFamily: 'Urbanist',
    border: '1px solid lightgray',
    fontSie: '16px',
    fontWeight: 400,
    lineHight: "24px",
    textAlign: "left",
    borderRadius: '8px',
    height: '50px',
    padding: '12px 16px',
    width: '100%'
  },
  "& .directionArabic": {
    direction: "ltr"
  },
  "& .countryCodeText": {
    textAlign: "center",
    paddingTop: "3px",
    paddingRight: "12px"
  },
  "& .messageInputField": {
    resize: "none",
    userDrag: "none",
    fontFamily: 'Urbanist',
    border: '1px solid #D0D5DD',
    boxShadow: "0px 1px 2px 0px #1018280D",
    fontSie: "16px",
    fontWeight: 400,
    lineHight: "24px",
    borderRadius: "8px",
    height: "126px",
    padding: "12px 16px",
    width: '100%',
    "& .messageInputField:focus": {
      borderColor: "#D0D5DD",
      outline: "none",
    }
  },
  "& .cardInputLabel": {
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: "4px",
    color: "#344054"
  },
  "& .privacyCheckbox": {
    width: '20px',
    height: '20px',
    gap: '0px',
    borderRadius: '6px 0px 0px 0px',
    opacity: '1',
    color: '#D0D5DD',
    padding: '0',
  },
  "& .privacyCheckboxArabic": {
    marginLeft: "12px",
    marginRight: "0px",
    width: '20px',
    height: '20px',
    gap: '0px',
    borderRadius: '6px 0px 0px 0px',
    opacity: '1',
    color: '#D0D5DD',
    padding: '0',
  },
  "& .privacyTypo": {
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "#344054"
  },
  "& .privacySpan": {
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    cursor: "pointer",
    color: "rgba(28, 107, 223, 1)",
    borderBottom: "1px solid rgba(28, 107, 223, 1)"
  },
  "& .sendMessageBtn": {
    height: "48px",
    backgroundColor: "#044352",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "16px",
    width: "100%",
    textTransform: "none",
    cursor: "pointer"
  },
  "& .cardGridMargin1": {
    display: "flex",
    flexDirection: "row",
    marginTop: "24px"
  },
  "& .cardGridMargin2": {
    display: "flex",
    flexDirection: "row"
  },
  "& .cardGridMargin3": {
    display: "flex",
    flexDirection: "row",
    marginTop: "32px"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: '#fff'
  },
  "& .errorStyle": {
    color: "#F30202",
    fontSize: "0.85rem",
    marginBottom: "0.5rem",
  },

  "& .successTitleWrapper": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .successTitle": {
    fontFamily: "Urbanist",
    fontWeight: 700,
    display: "inline",
    fontSize: "24px",
    lineHeight: "32px",
    margin: "8px 16px",
    color: "#000000"
  },
  "& .successCloseIcon": {
    margin: "8px 0px",
    padding: "0px",
    color: "#334155"
  },
  "& .successContentImageWrapper": {
    display: "flex",
    justifyContent: "center"
  },
  "& .successContent1": {
    fontFamily: "Urbanist",
    padding: "22px 16px 12px 16px",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#0F172A",
    textAlign: "center"
  },
  "& .successContent2": {
    fontFamily: "Urbanist",
    padding: "0px 16px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#0F172A",
    textAlign: "center"
  },
  "& .successContinueButtonWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 12px",
    margin: "24px 0px",
  },
  "& .successContinueButton": {
    padding: "16px 37.5px 16px 32.5px",
    width: "230px",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 700,
    borderColor: "#F1F5F9",
    borderRadius: "4px",
    gap: "8px",
    opacity: "0px",
    background: "#044352",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      background: "#044352",
    }
  }
})

// Customizable Area End
