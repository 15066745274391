import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Input,
    Card,
    CardContent,
    Chip,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button
} from "@mui/material";
import { backArrow, uploadImage, more, uploadedFile } from "./assets";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export const configJSON = require("./config");
// Customizable Area End

import CompanyDetailsController, {
    Props
} from "./CompanyDetailsController";

export default class CompanyDetails extends CompanyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    companyCulture = () => {
        const error = this.state.submitClicked && !this.state.companyCulture && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        Company culture <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <textarea
                    data-test-id="company_culture"
                    className={`${error ? "errorClass" : ""} cultureInputField`}
                    placeholder="Company culture"
                    value={this.state.companyCulture}
                    onChange={(event) => this.handleCopanyCulture(event)}
                />
                <Typography className="companyCultureLimit">{this.state.companyCulture.length}/1000</Typography>
            </Wrapper>
        )
    }

    industryType = () => {
        const error = this.state.submitClicked && !this.state.selectedIndustryType && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        Industry type  <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="industry_type"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedIndustryType}
                    renderValue={
                        this.state.selectedIndustryType
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your industry type
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleIndustryType}
                    input={<InputBase />}
                >
                    {this.state.industryType.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companySize = () => {
        const error = this.state.submitClicked && !this.state.selectedCompanySize && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        Company size  <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="company_size"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanySize}
                    onChange={this.handleCompanySize}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanySize
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your company size
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companySize.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    companyStage = () => {
        const error = this.state.submitClicked && !this.state.selectedCompanyStage && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        Company Stage  <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="company_stage"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedCompanyStage}
                    onChange={this.handleCompanyStage}
                    displayEmpty
                    renderValue={
                        this.state.selectedCompanyStage
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your company stage
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.companyStage.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    jdStyle = () => {
        const error = this.state.submitClicked && !this.state.selectedJdStyle && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        JD Style  <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    data-test-id="jd_style"
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    value={this.state.selectedJdStyle}
                    onChange={this.handleJdStyle}
                    displayEmpty
                    renderValue={
                        this.state.selectedJdStyle
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select your JD style
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    input={<InputBase />}
                >
                    {this.state.jdStyle.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    organizationKeywords = () => {
        const error = this.state.submitClicked && this.state.chipData.length < 1 && configJSON.errorText || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        Organization keywords <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Box className={`${error ? "errorClass" : ""} organizationKeywords`}>
                    <Input
                        className="orgKeywordInput"
                        fullWidth
                        data-test-id="organization_keywords"
                        placeholder="Enter your keywords here..."
                        value={this.state.organizationKeywords}
                        onChange={this.handleKeywordChange}
                        onKeyDown={this.handleAddChip}
                        sx={{
                            '&:before': {
                                borderBottom: 'none',
                            },
                            '&:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                            },
                        }}
                    />
                    <Box className="chipContainer">
                        {this.state.chipData.map((data, index) => {
                            return (
                                <Chip
                                    className="chip"
                                    key={index}
                                    label={data}
                                    data-test-id="organization_keywords_chip"
                                    onDelete={this.handleDeleteChip(data)}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Wrapper>
        )

    }

    jdTemplates = () => {
        const errorRequired = this.state.submitClicked && !this.state.selectedFile && configJSON.errorText || "";
        const errorMB = this.state.fileError.length > 0 && this.state.fileError || "";
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">                
                    <Typography className="cardInputLabel">
                        Upload JD templates  <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {errorMB}
                        {!errorMB && errorRequired}
                    </Typography>
                </Box>
                <Box className={`${(errorRequired.length > 0 || errorMB.length > 0) ? "errorClass" : ""} jdMainBox`}>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item xs={12} sm={2} md={1.5} className="jdInnerGrid">
                            <img
                                src={this.state.selectedFile ? uploadedFile.default : uploadImage.default}
                                alt="upload Image"
                                data-test-id="upload_image"
                                onClick={this.handleImageClick}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9} md={9.5} sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            },
                            alignItems: {
                                xs: 'center',
                                sm: 'flex-start',
                            },
                        }}>
                            <Typography className="jdUploadText">
                                {this.state.selectedFile ? this.state.selectedFile?.name : "Upload your JD document"}
                            </Typography>
                            <Typography className="jdUploadSubText">
                                {this.state.selectedFile
                                    ? `${(this.state.selectedFile.size / 1024).toFixed(2)} KB`
                                    : "You can upload word and pdf files up to 5 MB's"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={1} className="deleteJdImg">
                            <img src={more.default}
                                alt="Delete document"
                                onClick={this.handleFileDelete}
                                data-test-id="delete_template"
                            />
                        </Grid>
                    </Grid>
                    <input
                        id="upload-jd-input"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        style={{ display: 'none' }}
                        data-test-id="upload_template"
                        onChange={this.handleFileUpload}
                    />
                </Box>
            </Wrapper>
        )
    }

    submitButton = () => {
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Button className="sumbmitBtn" data-test-id="submit_company_details" onClick={this.handleCompanyDetailSubmit}>
                            <Typography className="sumbmitText">Submit</Typography>
                        </Button>
                    </Grid>
                </Grid>

            </Wrapper>
        )
    }

    companyFormHeader = () => {
        return (
            <Wrapper>
                <Box className="companyItemMainBox">
                    <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="companyCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Organisation details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.companyCulture()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.industryType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companySize()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.companyStage()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.jdStyle()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.organizationKeywords()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.jdTemplates()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.submitButton()}
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Company details" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                                    <Typography className="CompanyTitle">Company Details</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className="compMainHeaderGrid">
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.companyFormHeader()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .companyCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .companyItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .cardInputLabel": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .errorStyle": {
        color: "#F87171",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic"
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .companyCardContainer": {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .chipContainer": {
        borderTop: '1px solid #CBD5E1',
        padding: "12px 16px",
    },
    "& .chip": {
        border: '1px solid #CBD5E1',
        margin: "12px 12px 12px 0px",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        color: "#475361"
    },
    "& .organizationKeywords": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
    },
    "& .orgKeywordInput": {
        border: 0, padding: "24px 12px 24px"
    },
    "& .jdMainBox": {
        display: "flex",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "20px 5px"
    },
    "& .jdInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .deleteJdImg": {
        display: "flex",
        justifyContent: "center"
    },
    "& .jdUploadText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#0F172A"
    },
    "& .jdUploadSubText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B"

    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .cultureInputField": {
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: "8px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& .dropDownStyle": {
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 400,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 8px',
        width: '100%'
    },
    "& .dropDownStyleplaceHolder": {
        color: "#DCDCDC", fontSize: 16
    } as React.CSSProperties,

    "& .sumbmitBtn": {
        height: "65px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px 0px 0px 0px",
        background: "#044352",
        width: "100%"
    },
    "& .sumbmitText": {
        fontFamily: "Urbanist",
        fontFize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        color: "#FFFFFF"

    }
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .companyTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End