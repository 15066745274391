Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptintegration9";
exports.labelBodyText = "cfchatgptintegration9 Body";

exports.btnExampleTitle = "CLICK ME";

exports.POST="POST"
exports.createJobDescApiEndPoint= "bx_block_dashboardguests/job_descriptions"
exports.getJobDescLevelApiEndPoint= "bx_block_dashboardguests/job_descriptions/job_level_list"
exports.getJobDescExpApiEndPoint= `bx_block_dashboardguests/job_descriptions/job_experience_list`
// Customizable Area End
exports.jobLevel = [
  {value:"Engineer"},
  {value:"Software Developer"},
  {value:"Tech Lead"},
  {value:"Project Manager"}
]

exports.jdStyle = [
  {value:"Fresher"},
  {value:"0-1year"},
  {value:"1-1.5year"},
  {value:"1.5-2year"},
  {value:"2-2.5year"}
]

exports.keySkill = [
  {value:"React Js"},
  {value:"Node Js"},
  {value:"Typescript"},
  {value:"Next Js"},
  {value:"Javascript"}
]

exports.keyWord = [
  {value:"jsx"},
  {value:"node.js"},
  {value:"tsx"},
  {value:"next.js"},
  {value:"js"}
]

exports.headingPopup = "Under process";
exports.titlePopup = "Congratulations! Your job creation is under process";
exports.subTitlePopup = "Something magical is going to happen.";
exports.buttonText = "Preview";
exports.jobdesGen = "Job Description generator";
exports.genJobDesProgress = "Generating Job Descriptions...";
